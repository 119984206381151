import React from "react";
import loadable from "@loadable/component";

const Layout = loadable(() => import("@common/Layout/Layout"));
const ThankYou = loadable(() => import("@components/ThankYou/ThankYou"));
const SEO = loadable(() => import("@components/Common/SEO/SEO"));
const ThankYouPage = props => {
    const { path, location } = props;
    let isCareers;
    if (location.state) {
        isCareers = location.state.isCareers;
    }

    return (
        <>
            <SEO
                title="Thank you"
                titleTemplate="Impressit"
                description="Have a productive rest of the day and feel free to check our blog"
                path={path}
                ownMeta={{ name: "googlebot", content: "noindex, nofollow" }}
            />
            <Layout path={path}>
                <ThankYou isCareers={isCareers} />
            </Layout>
        </>
    );
};

export default ThankYouPage;
